<template>
	<div class="select-index">
		<div class="desc">单位护卫犬申办入口</div>
		<el-card shadow="hover" class="apply">
			<router-link to="/apply" tag="div">申办单位养犬</router-link>
		</el-card>
		<div class="desc">查询历史申办记录</div>
		<el-card shadow="hover" class="apply-list">
			<router-link to="/apply-list" tag="div">查询申办记录</router-link>
		</el-card>

    <el-button type="text" class="tips" @click="goLink">点击查看《单位养犬办理攻略》详情</el-button>
	</div>
</template>

<script>
export default {
    name: 'Index',
    data() {
        return {
            msg: '111'
        }
    },
    methods: {
        goLink() {
            window.location.href = "https://mp.weixin.qq.com/s/WwxHnShof4TliyuZSTsbVg"
        }
    }
}
</script>

<style lang="scss">
.select-index{
	width: 1100px;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
  position: relative;
	.desc {
		color: #999999;
		margin-bottom: 7px;
	}
  .tips {
    position: absolute;
    bottom: 42px;
    text-align: center;
    z-index: 1;
    cursor: pointer;
  }
	.el-card {
		width: 500px;
		cursor: pointer;
		margin-bottom: 30px;
		border-radius: 8px;
		font-size: 18px;
		.el-card__body {
			width: 100%;
			text-align: center;
		}
		&.apply {
			background-color: #2e92ff;
			color: #fff;
			&:hover {
				background-color: #046bda;
			}
		}
		&.apply-list {
			border: 1px solid #2e92ff;
			color: #046bda;
			&:hover {
				background-color: #2e92ff;
				color: #fff;
			}
		}
	}
}
</style>
